import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../../layout/Container"
import Gallery from "./Gallery"
import Button from "../../Button"
import styled from "@emotion/styled"

const ButtonContainer = styled.div`
  display: block;
  position: relative;
  margin: 1em 0;
  text-align: center;
`

export default ({ limit, moreButton }) => {
  const posts = useStaticQuery(graphql`
    query InstagramPosts {
      allInstagramContent {
        edges {
          node {
            caption
            link: permalink
            localImage {
              childImageSharp {
                fluid(maxHeight: 500, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)
  const galleyPosts = limit
    ? posts.allInstagramContent.edges.slice(0, limit)
    : posts.allInstagramContent.edges
  return (
    <Container fadeIn={true}>
      <Gallery posts={galleyPosts} />
      {moreButton && (
        <ButtonContainer>
          <Button url="/media">View more</Button>
        </ButtonContainer>
      )}
    </Container>
  )
}
