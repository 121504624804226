import { darken, lighten } from "polished"

export const bodyBackground = "#262626"
export const lighterShadow = `${lighten(0.08, bodyBackground)}`
export const darkerShadow = `${darken(0.1, bodyBackground)}`

export const textColor = "#fff"
export const textHoverColor = darken(0.3, textColor)

export const transitionDuration = "300ms"

export const bodyFont = `"Merriweather", serif`
export const headerFont = `"Raleway", sans-serif`

export const breakpointBelowLarge = `@media screen and (max-width: 950px)`
export const breakpointAboveLarge = `@media screen and (min-width: 950px)`
export const breakpointBelowSmall = `@media screen and (max-width: 540px)`
export const breakpointAboveSmall = `@media screen and (max-width: 540px)`
