import styled from "@emotion/styled"

const Main = styled.div`
  position: relative;
  max-width: 100%;
  min-height: 100%;
  &:after {
    position: fixed;
    width: 100%;
    height: 15%;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(224, 224, 219, 0) 0%,
      rgba(224, 224, 219, 1) 100%
    );
    pointer-events: none;
    z-index: 9999;
  }
`

export default Main
