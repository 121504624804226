import React from "react"
import styled from "@emotion/styled"
import getFadeInDelay from "../../utils/getFadeInDelay"

const Container = styled.section`
  max-width: 1500px;
  margin: 4em auto;
  padding: 0 2em;
  @media screen and (min-width: 540px) {
    padding: 0 4em;
  }
`

export default props => {
  const { fadeIn, fadeInDelay, children } = props
  return (
    <Container
      data-sal={fadeIn ? "fade" : null}
      data-sal-delay={fadeInDelay || getFadeInDelay()}
      data-sal-duration="900"
    >
      {children}
    </Container>
  )
}
