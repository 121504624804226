import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { lighterShadow, darkerShadow } from "../styles/const"
import Link from "./Link"

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: transform 300ms;
    pointer-events: none;
  }
  &:before {
    background: ${lighterShadow};
    transform: translate(-10%, -10%);
  }
  &:after {
    background: ${darkerShadow};
    transform: translate(10%, 10%);
  }
  &:hover:before,
  &:hover:after {
    transform: translate(0);
  }
`

export const styledImageFragment = graphql`
  fragment StyledImageFragment on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default ({ fluid, alt, linkData }) => {
  return (
    <ImageContainer>
      {linkData ? (
        <Link link={linkData.link} external={linkData?.external || null}>
          <Img fluid={fluid} alt={alt} />
        </Link>
      ) : (
        <Img fluid={fluid} alt={alt} />
      )}
    </ImageContainer>
  )
}
