import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FaInstagram, FaEnvelope, FaYoutube, FaFacebook } from "react-icons/fa"
import { IoLogoTiktok } from "react-icons/io5"
import Container from "./layout/Container"
import { fadeInSpeed } from "../const"
import {
  breakpointBelowSmall,
  textColor,
  textHoverColor,
  transitionDuration,
} from "../styles/const"
import InlineNav from "./features/InlineNav"
import getFadeInDelay from "../utils/getFadeInDelay"

const HeaderWrapper = styled.div`
  display: grid;
  grid-row: 2;
  ${breakpointBelowSmall} {
    display: block;
    text-align: center;
  }
`

const linkStyles = css`
  a {
    color: ${textColor};
    text-decoration: none;
    transition: color ${transitionDuration};
    &:hover {
      color: ${textHoverColor};
    }
  }
`

const HeaderLeft = styled.div`
  grid-column: 1;
`

const HeaderRight = styled.div`
  grid-column: 2;
  text-align: right;
  ${breakpointBelowSmall} {
    text-align: center;
  }
`

const LogoTextHeader = styled.h1`
  font-size: 2em;
`

export default () => (
  <Container>
    <HeaderWrapper>
      <HeaderLeft css={linkStyles}>
        <LogoTextHeader
          data-sal="slide-down"
          data-sal-duration={fadeInSpeed}
          data-sal-delay={getFadeInDelay(true)}
        >
          <a href="/">Johnny Tuosto</a>
        </LogoTextHeader>
      </HeaderLeft>
      <HeaderRight css={linkStyles}>
        <InlineNav
          items={[
            {
              link: "/media",
              text: "Media",
            },
          ]}
        />
        <InlineNav
          items={[
            {
              external: true,
              link: "https://www.instagram.com/johnnytuosto/",
              icon: <FaInstagram />,
            },
            {
              external: true,
              link: "https://www.facebook.com/johnnytuosto",
              icon: <FaFacebook />,
            },
            {
              external: true,
              link: "https://www.youtube.com/channel/UC1omLw0_1HAgfieN5KHdXSg",
              icon: <FaYoutube />,
            },
            {
              external: true,
              link: "https://www.tiktok.com/@johnnytuosto",
              icon: <IoLogoTiktok />,
            },
            {
              external: true,
              link: "mailto:jtuosto15@gmail.com",
              icon: <FaEnvelope />,
            },
          ]}
        />
      </HeaderRight>
    </HeaderWrapper>
  </Container>
)
