import React from "react"
import styled from "@emotion/styled"
import { fadeInSpeed } from "../../const"
import { Link } from "gatsby"
import getFadeInDelay from "../../utils/getFadeInDelay"
import {
  breakpointBelowSmall,
  headerFont,
  lighterShadow,
  transitionDuration,
} from "../../styles/const"

const NavContainer = styled.nav`
  height: 100%;
  text-align: right;
  font-size: 1.5em;
  margin: 5px 0 0 20px;
  display: inline-block;
  ${breakpointBelowSmall} {
    display: block;
    text-align: center;
    margin: 1.5rem 0;
  }
  li {
    display: inline-block;
    margin-left: 10px;
    height: 100%;
    vertical-align: middle;
    ${breakpointBelowSmall} {
      margin-left: 15px;
    }
    &.nav-item-with-text {
      font-family: ${headerFont};
      font-size: 1.2rem;
      position: relative;
      a:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.3rem;
        bottom: -0.5rem;
        left: 0;
        background: ${lighterShadow};
        opacity: 0;
        transform: translateY(1rem);
        transition: transform ${transitionDuration},
          opacity ${transitionDuration};
      }
      a:hover:after {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
`

export default ({ items }) => (
  <NavContainer>
    <ul>
      {items.map((item, i) => (
        <li
          key={i}
          className={item.icon ? "nav-item-with-icon" : "nav-item-with-text"}
          data-sal="slide-down"
          data-sal-duration={fadeInSpeed}
          data-sal-delay={getFadeInDelay(true)}
        >
          {item.external ? (
            <a href={item.link} rel="noopener noreferrer" target="_blank">
              {item.text || item.icon}
            </a>
          ) : (
            <Link to={item.link}>{item.text || item.icon}</Link>
          )}
        </li>
      ))}
    </ul>
  </NavContainer>
)
