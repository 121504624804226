import React from "react"
import styled from "@emotion/styled"
import Container from "./Container"

const TextContainer = styled.div`
  max-width: 900px;
  margin-top: 0;
  &.centered-text {
    text-align: center;
  }
  @media screen and (min-width: 950px) {
    margin: 7em auto 4em auto;
  }
`

export default ({ centerText, children }) => (
  <Container fadeIn={true}>
    <TextContainer className={centerText ? "centered-text" : null}>
      {children}
    </TextContainer>
  </Container>
)
