import React from "react"
import GlobalStyles from "../GlobalStyles";
import Header from "../Header";
import SEO from "../SEO";
import Main from "./Main";

export default ({children}) => [
    <>
    <SEO/>
    <Header/>
    <GlobalStyles/>
    <Main>
        {children}
    </Main>
    </>
];