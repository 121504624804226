import React from "react"
import styled from "@emotion/styled"
import LocalImage from "../../LocalImage"
import {
  breakpointBelowLarge,
  breakpointBelowSmall,
} from "../../../styles/const"

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5vw;
  row-gap: 5vw;
  margin: 4vw auto;
  ${breakpointBelowLarge} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10vw;
    row-gap: 10vw;
  }
  ${breakpointBelowSmall} {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 20vw;
    padding: 5vw;
    margin: 8vw auto;
  }
`

export default props => (
  <GalleryContainer>
    {props.posts.map(
      (item, i) =>
        item.node.localImage.childImageSharp && (
          <LocalImage
            fluid={item.node.localImage.childImageSharp.fluid}
            alt={item.node.caption || "Instagram Post"}
            linkData={{ link: item.node.link, external: true }}
          />
        )
    )}
  </GalleryContainer>
)
