import React from "react"
import styled from "@emotion/styled"
import { lighten } from "polished"

const Button = styled.a`
  padding: 1em 3em;
  display: inline-block;
  background-color: #000;
  color: #fff;
  transition: background-color 300ms;
  text-decoration: none;
  &:hover {
    background-color: ${lighten(0.3, "#000")};
  }
`

export default ({ url, children }) => <Button href={url}>{children}</Button>
