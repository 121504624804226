import { fadeInStep } from "../const"

let fadeInDelay = 0

export default incr => {
  const delay = fadeInDelay
  if (incr) {
    fadeInDelay = fadeInDelay + fadeInStep
  }
  return delay
}
