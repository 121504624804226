import { Global, css } from "@emotion/core"
import React from "react"
import {
  bodyBackground,
  bodyFont,
  headerFont,
  textColor,
} from "../styles/const"

export default () => (
  <Global
    styles={css`
      html {
        background: ${bodyBackground};
        font-family: "Merriweather", serif;
        color: ${textColor};
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${headerFont};
      }

      h2 {
        font-size: 3em;
        margin-bottom: 1em;
      }

      p {
        font-family: ${bodyFont};
        color: ${textColor};
        font-size: 1.2em;
        line-height: 1.6em;
        margin-bottom: 1em;
        @media screen and (min-width: 540px) {
          font-size: 1.5em;
        }
      }

      .footer {
        background: #2a2829;
      }

      h1 {
        font-size: 6em;
        font-weight: 900;
      }
    `}
  />
)
