import React from "react"
import { Link as GatsbyLink } from "gatsby"

export default ({ link, external, children }) => {
  return external ? (
    <a href={link} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <GatsbyLink to={link}>{children}</GatsbyLink>
  )
}
